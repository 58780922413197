import React, { useState, useEffect } from 'react';
import teamData from './team-data.json';

// components
import { NavLink } from "react-router-dom";
import Button from '../../components/button';
import Flex from '../../components/flex';
import Section from '../../components/section';
import GmailIcon from '../../components/svg/email-icon.png';
import LinkedinIcon from '../../components/svg/linkedinIcon';
import TelegramIcon from '../../components/svg/telegramIcon';
import Text from '../../components/text';
import Footer from '../include/footer';
import Header from '../include/header';

// all team member images
import garvImg from '../../assets/img/garv.jpg';
import rishabImg from '../../assets/img/rishab.jpg';
import akanshaImg from '../../assets/img/akansha.jpg';
import sangeetaImg from '../../assets/img/sangeeta.jpg';
import manishaImg from '../../assets/img/manisha.jpg';
import saiPoojaImg from '../../assets/img/pooja.jpg';
import ajayImg from '../../assets/img/ajay.jpg';

const imageMap = {
  'garv.jpg': garvImg,
  'rishab.jpg': rishabImg,
  'akansha.jpg': akanshaImg,
  'sangeeta.jpg': sangeetaImg,
  'manisha.jpg': manishaImg,
  'pooja.jpg': saiPoojaImg,
  'ajay.jpg': ajayImg
};

const TeamMemberCard = ({ image, name, position, email, linkedin, telegram, category }) => {
  return (
    <div className="team-member-card">
      <div className="team-member-image">
       <img src={imageMap[image]} />
      </div>
      <div class="team-member-details">
       <Text type="H3" className="team-member-name" text={name} />
       <Text type="H5" className="team-member-position" text={position} />
      </div>
      <div className="team-member-social">
        <NavLink to={`mailto:${email}`} aria-label={`Email ${name}`}>
          <img src={GmailIcon} width={20} height={20}/>
        </NavLink>
        <NavLink to={linkedin} aria-label={`${name}'s LinkedIn profile`}>
          <LinkedinIcon />
        </NavLink>
        {category === 'business' && (
          <NavLink to={telegram} aria-label={`${name}'s Telegram`}>
            <TelegramIcon />
          </NavLink>
        )}
      </div>
    </div>
  );
};

const OurTeamsView = () => {
  const [selectedCategory, setSelectedCategory] = useState('business');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Section containerWidth1100 className="section">
        <div className="content-wrapper">
          <Text
            type="H1"
            className="caption-title"
            text="The PayOnRamp Team"
          />
          <Text
            type="PARAGRAPH"
            className="caption-text"
            text="The PayOnRamp Team consists of dedicated talents who built successful careers in various fields before joining the crypto industry. The team's mission is to deliver value to our users and clients by building a world class crypto-exchange platform that benefits users, investors, and listing project parties. As part of the crypto community, we strongly believe in collaborations."
          />
        </div>
      </Section>

      <Section containerWidth1100 className="section-team-members">
        <Flex flexColumn justifyContent="center" alignItems="center">
          <Text
            type="H2"
            className="section-title text-center mb-5"
            text="Our Team Members"
          />
          <div className="team-category-buttons mb-4">
            <Button
              text="Business Development"
              type="button"
              onClick={() => setSelectedCategory('business')}
              white={selectedCategory !== 'business'}
            />
            <Button
              text="Authorized Payment Personnel"
              type="button"
              onClick={() => setSelectedCategory('authorizedPaymentPersonnel')}
              white={selectedCategory !== 'authorizedPaymentPersonnel'}
            />
            <Button
              text="Operations"
              type="button"
              onClick={() => setSelectedCategory('operations')}
              white={selectedCategory !== 'operations'}
            />
          </div>
          <div className="row">
            {teamData[selectedCategory].map((member, index) => (
              <div key={index} className="col-12 col-md-4 mb-4">
                <TeamMemberCard {...member} category={selectedCategory} />
              </div>
            ))}
          </div>
        </Flex>
      </Section>

      <Section containerWidth1100 className="section-join-our-team">
        <Flex flexColumn justifyContent="center" alignItems="center">
          <Text
            type="H2"
            className="section-title text-center"
            text="Join Our Team and Shape the Future of Crypto Transactions!"
          />
        </Flex>
      </Section>
      <Footer />
    </>
  );
};

export default OurTeamsView;