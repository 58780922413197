import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TandCView from './views/TandCView';
import Layout from '../include/layout';

const TandCController = (props) => {
  useEffect(() => {
    document.body.classList.add('agreement-page');
    return () => {
      document.body.classList.remove('agreement-page');
    };
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Terms and Condition - PayOnRamp</title>
        <meta
          name="description"
          content="Understand the rules and guidelines governing your interactions with PayOnRamp. Explore our comprehensive Terms and Conditions to ensure a transparent and trusted partnership."
        />
        <link rel="canonical" href="https://payonramp.com/termsAndCondations" />
      </Helmet>
      <TandCView />
    </Layout>
  );
};

export default TandCController;
